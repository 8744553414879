import * as React from "react"
import BrandLogo from "./brand-logo"
import { Link } from "gatsby";


const Footer = () => {
  return (
    <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div className="grid gap-16 py-14 row-gap-10 mb-8 lg:grid-cols-6">
        <div className="md:max-w-md lg:col-span-2">
          <a
            href="/"
            aria-label="Go home"
            title="Company"
            className="inline-flex items-center"
          >
           <BrandLogo></BrandLogo>

            <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
            </span>
          </a>
          <div className="mt-4 lg:max-w-sm">

          </div>
        </div>
        <div className="grid grid-cols-1 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4">
          <div>
          </div>
        
          <div>

          </div>
          <div>
            <p className="font-semibold  text-gray-800"></p>
            <ul className="mt-2 inline-flex md:pl-20 space-x-4">
              <li>
                <Link
                  to="/services"
                  className="text-white text-lg transition-colors duration-300 hover:text-[#005BEA]"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link 
                  to="/projects"
                  className="text-white text-lg transition-colors duration-300 hover:text-[#005BEA]"
                >
                  Projects
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="text-white text-lg transition-colors duration-300 hover:text-[#005BEA]"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="mailto:info@aikate.hr"
                  className="text-white text-lg transition-colors duration-300 hover:text-[#005BEA]"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between pt-5 pb-10  sm:flex-row">
        <p className="text-sm text-gray-600">
          © Copyright 2022 aiKATE. All rights reserved.
        </p>
        <div className="flex items-center mt-4 space-x-4 sm:mt-0">
          <a href="/privacy" className="text-sm text-gray-600">Privacy Policy</a>
          <a
            href="https://www.linkedin.com/company/11691602/"
            className="text-gray-500 transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="white" className="h-5" width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" /></svg>
          </a>
          <a
            href="https://instagram.com/vend.log?utm_medium=copy_link"
            className="text-gray-500 transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="white" className="h-5" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" /></svg>
          </a>
          <a
            href="https://www.facebook.com/vendlog/"
            className="text-gray-500 transition-colors"
          >
            <svg viewBox="0 0 24 24" fill="white" className="h-5">
              <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
            </svg>
          </a>
          <a
            href="https://www.youtube.com/channel/UCbnJDhCNUyEvlpBAyqy7uJA"
            className="text-gray-500 transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="white" className="h-5" width="24" height="24" viewBox="0 0 24 24"><path d="M10 9.333l5.333 2.662-5.333 2.672v-5.334zm14-4.333v14c0 2.761-2.238 5-5 5h-14c-2.761 0-5-2.239-5-5v-14c0-2.761 2.239-5 5-5h14c2.762 0 5 2.239 5 5zm-4 7c-.02-4.123-.323-5.7-2.923-5.877-2.403-.164-7.754-.163-10.153 0-2.598.177-2.904 1.747-2.924 5.877.02 4.123.323 5.7 2.923 5.877 2.399.163 7.75.164 10.153 0 2.598-.177 2.904-1.747 2.924-5.877z" /></svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
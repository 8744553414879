import * as React from "react"
import { Helmet } from "react-helmet"
import { StructuredData } from "./structured-data"

export default function Head({ title, description, image }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en-us",
      }}
    >
      <meta charSet="utf-8" />
      <title>{title}</title>
      {description && (
        <meta
          name="description"
          property="og:description"
          content={description}
        />
      )}
      <meta property="og:title" content={title} />
      {image && <meta property="og:image" content={image.url} />}
      <meta
        name="google-site-verification"
        content="mHAu5tprqPAOMauBOJyw0I-irJOez0qJCWC26QPGGVk"
      />
      <StructuredData>
        {{
          "@context": "http://schema.org",
          "@type": "LocalBusiness",
          name: "aiKATE",
          image: "https://aikate.eu/",
          telephone: "+385 1 4628 280",
          email: "info@aikate.hr",
          address: {
            "@type": "PostalAddress",
            streetAddress: "Gradišće 5",
            addressLocality: "Zagreb",
            addressCountry: "Croatia",
            postalCode: "10 000",
          },
          openingHoursSpecification: {
            "@type": "OpeningHoursSpecification",
            dayOfWeek: {
              "@type": "DayOfWeek",
              name: "Monday, Tuesday, Wednesday, Thursday, Friday",
            },
          },
          url: "https://aikate.eu/",
        }}
      </StructuredData>
    </Helmet>
  )
}
